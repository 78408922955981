<template>
  <div>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-card min-height="600">
      <v-form>
        <v-card-text>
          <v-container>
            <v-tabs v-model="tab" centered dark>
              <v-tabs-slider></v-tabs-slider>
              <v-tab class="no-uppercase" href="#tab-1">
                Dados Principais
              </v-tab>
              <v-tab class="no-uppercase" href="#tab-2">
                Complemento e Tributação
              </v-tab>
              <v-tab class="no-uppercase" href="#tab-3">
                Histórico de Vendas
              </v-tab>
            </v-tabs>
            <br />
            <v-tabs-items v-model="tab">
              <v-tab-item :value="'tab-1'">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Nome"
                      v-model="name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-select
                      v-model="productType"
                      :items="types"
                      item-text="name"
                      item-value="name"
                      label="Tipo"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-select
                      v-model="origin"
                      :items="[{ name: 'Nacional' }, { name: 'Importado' }]"
                      item-text="name"
                      item-value="name"
                      label="Origem"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Modelo"
                      v-model="model"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Marca"
                      v-model="brand"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Fabricante"
                      v-model="manufacturer"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Códgo de Barras"
                      v-model="barCode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="NCM"
                      v-model="ncm"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Preço de Custo"
                      v-model="costPrice"
                      v-money="money"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Preço de Venda"
                      v-model.lazy="price"
                      v-money="money"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Preço Promocional"
                      v-model.lazy="promotionalPrice"
                      v-money="money"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      :items="categories"
                      v-model="categoryId"
                      label="Categoria"
                      item-text="name"
                      item-value="id"
                      :loading="loading"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      :items="stores"
                      v-model="companyId"
                      label="Loja"
                      item-text="company.fantasyName"
                      item-value="id"
                      :loading="loading"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Descrição Completa"
                      v-model="description"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="!id" color="red" text @click="clear">
                    Limpar
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    color="success"
                    @click="registerOrUpdate()"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-tab-item>
              <v-tab-item :value="'tab-2'">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="aliquot" label="Aliquota" />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="tributeId"
                      :items="tributeItems"
                      item-text="name"
                      item-value="id"
                      label="Tributo"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-btn fab @click="addTribute">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-data-table :items="tributes" :headers="headers">
                </v-data-table>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="!id" color="red" text @click="clear">
                    Limpar
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    color="success"
                    @click="registerOrUpdate()"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
export default {
  name: "ProductForm",
  directives: { money: VMoney },
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    headers: [
      {
        text: "Tributo",
        value: "tribute.name",
      },
      {
        text: "Alíquota",
        value: "aliquot",
      },
    ],
    tab: null,
    aliquot: 0,
    product: {
      price: "",
    },
    categories: [],
    stores: [],
    tributeItems: [],
    tributes: [],
    tributeModal: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    types: [
      {
        name: "Produto",
      },
      {
        name: "Serviço",
      },
    ],
    loading: false,
    tributeId: "",
    id: "",
    companyId: "",
    name: "",
    origin: "",
    description: "",
    fullDescription: "",
    brand: "",
    manufacturer: "",
    costPrice: "",
    price: "",
    promotionalPrice: "",
    categoryId: "",
    productType: "",
    ncm: "",
    barCode: "",
    model: "",
    stock: 0,
    unity: "",
    unityQuantity: 1,
    weight: 0,
    height: 0,
    width: 0,
    depth: 0,
  }),
  methods: {
    addTribute() {
      const tr = http.get(`tributes/${this.tributeId}`).then((data) => {
        const pay = {
          tribute: data.data,
          aliquot: this.aliquot,
          tribuiteId: data.data.id,
        };
        this.tributes.push(pay);
      });
    },
    async verifyProduct() {
      const id = this.$route.params.productId;
      if (id) {
        this.price = this.$route.params.price;
        this.promotionalPrice = this.$route.params.promotionalPrice;
        this.costPrice = this.$route.params.costPrice;
        await http.get(`products/${id}`).then(
          (data) => {
            this.id = id;
            this.name = data.data.name;
            this.ncm = data.data.ncm;
            this.description = data.data.description;
            this.fullDescription = data.data.fullDescription;
            this.price = data.data.price.toString();
            this.costPrice = data.data.costPrice;
            this.promotionalPrice = data.data.promotionalPrice;
            this.productType = data.data.productType;
            this.stock = data.data.stock;
            this.unity = data.data.unity;
            this.unityQuantity = data.data.unityQuantity;
            this.depth = data.data.depth;
            this.width = data.data.width;
            this.height = data.data.height;
            this.weight = data.data.weight;
            this.model = data.data.model;
            this.brand = data.data.brand;
            this.manufacturer = data.data.manufacturer;
            this.barCode = data.data.barCode;
            this.categoryId = data.data.categoryId;
            this.tributes = data.data.tributes;
            this.companyId = data.data.companyId;
            this.product.price = data.data.toString();
          },
          (err) => {
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.snackbar.text =
              "Ocorreu um erro no Servidor ao buscar o produto, tente novamente mais tarde";
          }
        );
      }
    },
    registerOrUpdate() {
      this.loading = true;
      let priceR = this.price.replace("R$ ", "");
      priceR = priceR.replace(".", "");
      priceR = priceR.replace(",", ".");

      let promotionalPrice = this.promotionalPrice.replace("R$ ", "");
      promotionalPrice = promotionalPrice.replace(".", "");
      promotionalPrice = promotionalPrice.replace(",", ".");

      let costPrice = this.costPrice.replace("R$ ", "");
      costPrice = costPrice.replace(".", "");
      costPrice = costPrice.replace(",", ".");

      const payload = {
        price: priceR,
        promotionalPrice,
        name: this.name,
        ncm: this.ncm,
        description: this.description,
        fullDescription: this.fullDescription,
        productType: this.productType,
        unity: this.unity,
        unityQuantity: this.unityQuantity,
        depth: this.depth,
        width: this.width,
        height: this.height,
        weight: this.weight,
        model: this.model,
        brand: this.brand,
        barCode: this.barCode,
        costPrice,
        manufacturer: this.manufacturer,
        categoryId: this.categoryId,
        companyId: this.companyId,
        tributes: this.tributes,
      };
      if (this.id) {
        http.put(`products/${this.id}`, payload).then(
          (data) => {
            this.snackbar.color = "green";
            this.snackbar.text = "Produto Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loading = false;
          },
          (err) => {
            this.snackbar.color = "red";
            this.snackbar.text =
              "Ocorreu um erro ao atualizar o produto, Revise os dados e tente novamente";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      } else {
        http.post(`products`, payload).then(
          (data) => {
            this.snackbar.color = "green";
            this.snackbar.text = "Produto Cadastrado com Sucesso!";
            this.snackbar.opened = true;
            this.loading = false;
          },
          (err) => {
            this.snackbar.color = "red";
            this.snackbar.text =
              "Ocorreu um erro ao cadastar o produto, Revise os dados e tente novamente";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      }
    },
    getTributes() {
      this.loading = true;
      http.get("tributes").then((data) => {
        this.tributeItems = data.data;
        this.loading = false;
      });
    },
    getStores() {
      this.loading = true;
      http.get("company/stores").then((data) => {
        this.stores = data.data;
        this.loading = false;
      });
    },
    getCategories() {
      this.loading = true;
      http.get("categories").then((data) => {
        this.categories = data.data;
        this.loading = false;
      });
    },
    clear() {
      this.id = "";
      this.companyId = "";
      this.name = "";
      this.origin = "";
      this.description = "";
      this.fullDescription = "";
      this.brand = "";
      this.manufacturer = "";
      this.costPrice = "";
      this.price = "";
      this.promotionalPrice = "";
      this.categoryId = "";
      this.productType = "";
      this.ncm = "";
      this.barCode = "";
      this.model = "";
      this.stock = 0;
      this.unity = "";
      this.unityQuantity = 1;
      this.weight = 0;
      this.height = 0;
      this.width = 0;
      this.depth = 0;
    },
  },
  async mounted() {
    await this.verifyProduct();
    this.getCategories();
    this.getTributes();
    this.getStores();
  },
};
</script>

<style>
.no-uppercase {
  text-transform: none !important;
}
</style>